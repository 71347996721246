import './App.css';
import { NavBar } from './components/navbar/NavBar';
import packagejson from '../package.json';
import { resumeData } from './data';
import { ResumePage } from './components/resume/ResumePage';

function App() {
  return (
    <div className="App">
      <NavBar resume={resumeData} version={packagejson.version}/>
      <ResumePage data={resumeData}/>
    </div>
  );
}

export default App;
