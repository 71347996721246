import { WorkItemModel } from "../../definitions";
import { ContentElement } from "./ContentElement";
import { ElementProps } from "./Elements";
import "./WorkSectionItemElement.css";

export interface WorkSectionItemElementProps extends ElementProps {
  workSectionItemElement: WorkItemModel;
}
export function WorkSectionItemElement(props: WorkSectionItemElementProps) {
  return (
    <div className='WorkSectionItemElement'>
      <div className='heading'>
        {
          props.workSectionItemElement.type ?
            <span className="type">{
              props.workSectionItemElement.type
            }</span> : ''}
        <h4>{props.workSectionItemElement.title}</h4>
      </div>
      <div className='content'>
        {
          props.workSectionItemElement?.content !== undefined ?
            <ContentElement content={props.workSectionItemElement.content} /> : ''
        }
      </div>
    </div>
  );
}
